<h2 mat-dialog-title *ngIf="data.title">{{data.title}}</h2>
<mat-dialog-content>
    <p class="mat-body" *ngIf="data.text">
        {{data.text}}
    </p>
    <mat-form-field>
        <mat-label>{{data.placeholder}}</mat-label>
        <input [ngModel]="data.initialValue" matInput #input (keydown.enter)="confirm(input.value)"/>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close>{{data.cancelText}}</button>
    <button mat-button color="primary" (click)="confirm(input.value)">{{data.confirmText}}</button>
</mat-dialog-actions>
