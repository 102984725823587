<mat-sidenav-container style="min-height: 100vh">
    <div class="home">
        <div class="header">
            <h1>
                Naheulbook
            </h1>
            <img alt="logo Naheulbook" src="assets/favicon/android-chrome-192x192.png">
        </div>
        <div>
            <nav>
                <button mat-button [routerLink]="['/player/character/list']">Joueur</button>
                <button mat-button [routerLink]="['/gm/group/list']">Maitre du Jeu</button>
                <button mat-button [routerLink]="['/database']">Database</button>
            </nav>
        </div>
        <mat-card class="summary">
            <mat-card-content>
                <p>
                    Naheulbook est un site/application gratuit permettant de gérer les fiches de
                    personnages du <a href="http://naheulbeuk.com/">jeu de rôle Naheulbeuk</a>
                    simplement.
                </p>
                <p>
                    Cette application n'a pas pour but d'automatiser le jeu, ou de faire un équivalent
                    de Roll20, mais juste de simplifier la gestion de certains mécanismes complexes ou
                    qui prenne du temps, et de pouvoir plus se concentrer sur le roleplay.
                </p>
                <p>
                    Bien évidemment il y a des bugs dans l'application et des interpretation des règles
                    ont été faites. N'oubliez pas que c'est le MJ qui a le dernier mot, pas le code de
                    l'application.
                </p>
                <p>
                    L'application est encore en cours de développement et il manque encore certaines
                    choses. Le
                    <a target="_blank" href="https://github.com/Socolin/Naheulbook/">
                        code source est disponible
                    </a>
                    ainsi que le
                    <a target="_blank" href="https://github.com/Socolin/Naheulbook/issues">
                        gestionnaire de ticket
                    </a>
                    afin de suivre les fonctionnalités en cours de développement ou de signaler un bug.
                </p>
                <p class="discord-info">
                    <img src="assets/brands/Discord-Logo-Color.svg" width="64" height="64">
                    <span>
                        Vous pouvez aussi poser vos questions ou signaler un bug sur le discord du <a target="_blank"  href="https://discord.gg/hmZhDCdZy7">Gnome Archiviste</a>.
                        Dans les salons <span class="channel">#naheulbook</span>
                    </span>
                </p>
                <p>
                    <a href="/privacy.html">Politique de confidentialité</a>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</mat-sidenav-container>
